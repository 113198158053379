const store = {
  debug: false,
  state: {
    language: window.Craft.language,
    showCookieBar: false,
    hasModal: false,
    showMenuOverlay: false,
    articles: null,
    events: null,
    verdicts: null,
    urlString: window.location.href,
    practiceAreaSlug: '',
    industryTopicSlug: '',
    articleTypeSlug: '',
    jobFunctionSlug: '',
    eventCity: '',
    tagsSlug: '',
    personSlug: '',
    hasEventCity: false,
    newUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname.split('@')[0]}@`,
  },
  toggleMenuOverlayAction() {
    if (this.debug) console.log('toggleMentoggleMenuOverlayActionuAction triggered');
    this.state.showMenuOverlay = !this.state.showMenuOverlay;
  },
  toggleModalAction() {
    if (this.debug) console.log('toggleModalAction triggered');
    this.state.hasModal = !this.state.hasModal;
  },
  setArticlesAction(object) {
    if (this.debug) console.log('setArticlesAction triggered with', object);
    this.state.articles = object;
  },
  setEventsAction(object) {
    if (this.debug) console.log('setEventsAction triggered with', object);
    this.state.events = object;
  },
  setVerdictsAction(object) {
    if (this.debug) console.log('setVerdictsAction triggered with', object);
    this.state.verdicts = object;
  },
  setActiveProjectAction(object) {
    if (this.debug) console.log('setActiveProjectAction triggered with', object);
    this.state.activeProject = object;
  },
  changeFiltersAction(e) {
    let getPracticeAreaFromUrl = '';
    let getIndustryTopicFromUrl = '';
    let getArticleTypeFromUrl = '';
    let getEventCityFromUrl = '';
    let getJobFunctionFromUrl = '';
    let getTagsFromUrl = '';
    let getPersonFromUrl = '';

    // set filters from url
    const url = new URL(this.state.urlString);
    let filters = url.pathname.split('@')[1];
    if (filters) {
      filters = filters.split(';');
      // eslint-disable-next-line
      for (const i in filters) {
        const filter = filters[i];
        if (filter.indexOf('practice-area') !== -1) {
          getPracticeAreaFromUrl = filter.split(':')[1];
        }
        if (filter.indexOf('industry-topic') !== -1) {
          getIndustryTopicFromUrl = filter.split(':')[1];
        }
        if (filter.indexOf('article-type') !== -1) {
          getArticleTypeFromUrl = filter.split(':')[1];
        }
        if (filter.indexOf('event-city') !== -1) {
          getEventCityFromUrl = filter.split(':')[1];
        }
        if (filter.indexOf('job-function') !== -1) {
          getJobFunctionFromUrl = filter.split(':')[1];
        }
        if (filter.indexOf('tags') !== -1) {
          getTagsFromUrl = filter.split(':')[1];
        }
        if (filter.indexOf('person') !== -1) {
          getPersonFromUrl = filter.split(':')[1];
        }
      }
    }
    // fill the params
    if (getPracticeAreaFromUrl !== '') {
      this.state.practiceAreaSlug = getPracticeAreaFromUrl;
    }
    if (getJobFunctionFromUrl !== '') {
      this.state.jobFunctionSlug = getJobFunctionFromUrl;
    }
    if (getIndustryTopicFromUrl !== '') {
      this.state.industryTopicSlug = getIndustryTopicFromUrl;
    }
    if (getArticleTypeFromUrl !== '') {
      this.state.articleTypeSlug = getArticleTypeFromUrl;
    }
    if (getEventCityFromUrl !== '') {
      this.state.eventCity = getEventCityFromUrl;
    }
    if (getTagsFromUrl !== '') {
      this.state.tagsSlug = getTagsFromUrl;
    }
    if (getPersonFromUrl !== '') {
      this.state.personSlug = getPersonFromUrl;
    }
    // set the params with selected from user values
    if (this.debug) console.log('changeFiltersAction triggered');
    if (e.target.id === 'industry-topics') {
      if (e.target.options.selectedIndex > -1) {
        if (this.debug) console.log(
          'set industryTopicSlug',
          e.target.options[e.target.options.selectedIndex].value,
        );
        this.state.industryTopicSlug =          e.target.options[e.target.options.selectedIndex].value;
      }
    } else if (e.target.id === 'article-type') {
      if (e.target.options.selectedIndex > -1) {
        if (this.debug) console.log(
          'set article type',
          e.target.options[e.target.options.selectedIndex].value,
        );
        this.state.articleTypeSlug =          e.target.options[e.target.options.selectedIndex].value;
      }
    } else if (e.target.id === 'event-cities') {
      if (e.target.options.selectedIndex > -1) {
        if (this.debug) console.log(
          'set event city',
          e.target.options[e.target.options.selectedIndex].value,
        );
        this.state.eventCity =          e.target.options[e.target.options.selectedIndex].value;
      }
    } else if (e.target.id === 'practice-areas') {
      if (e.target.options.selectedIndex > -1) {
        if (this.debug) console.log(
          'set practiceAreaSlug',
          e.target.options[e.target.options.selectedIndex].value,
        );
        this.state.practiceAreaSlug =          e.target.options[e.target.options.selectedIndex].value;
      }
    } else if (e.target.id === 'job-functions') {
      if (e.target.options.selectedIndex > -1) {
        if (this.debug) console.log(
          'set jobFunctionSlug',
          e.target.options[e.target.options.selectedIndex].value,
        );
        this.state.jobFunctionSlug =          e.target.options[e.target.options.selectedIndex].value;
      }
    }

    // create url
    const filterParams = [];
    if (this.state.practiceAreaSlug !== '') {
      filterParams.push(`practice-area:${this.state.practiceAreaSlug}`);
    }
    if (this.state.jobFunctionSlug !== '') {
      filterParams.push(`job-function:${this.state.jobFunctionSlug}`);
    }
    if (this.state.industryTopicSlug !== '') {
      filterParams.push(`industry-topic:${this.state.industryTopicSlug}`);
    }
    if (this.state.articleTypeSlug !== '') {
      filterParams.push(`article-type:${this.state.articleTypeSlug}`);
    }
    if (this.state.eventCity !== '') {
      filterParams.push(`event-city:${this.state.eventCity}`);
    }
    if (this.state.tagsSlug !== '') {
      filterParams.push(`tags:${this.state.tagsSlug}`);
    }
    if (this.state.personSlug !== '') {
      filterParams.push(`person:${this.state.personSlug}`);
    }
    this.state.newUrl += filterParams.join(';');

    window.history.pushState({ path: this.state.newUrl }, '', this.state.newUrl);
    if (this.debug) console.log('the new url', this.state.newUrl);
    window.location.href = this.state.newUrl;
  },
};

export default store;
